import { format, formatDistance, differenceInMonths } from "date-fns";

export function formatDate(date, { greaterThanPrefix, lessThanPrefix } = {}) {
    const isGreaterThanOneMonth = differenceInMonths(new Date(), date) >= 1;

    if (isGreaterThanOneMonth) {
        if (greaterThanPrefix) return format(date, `'${greaterThanPrefix}' do 'of' MMM',' yyyy`);
        return format(date, "do 'of' MMM',' yyyy");
    } else {
        if (lessThanPrefix) return `${lessThanPrefix} ${formatDistance(date, new Date())} ago`;
        return `${formatDistance(date, new Date())} ago`;
    }
}
